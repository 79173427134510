/* eslint-disable import/order */
import React from 'react';
import { useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import { useNavigate } from "react-router-dom";
import {
  ExtensionLoginButton,
  WebWalletLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton
} from '@multiversx/sdk-dapp/UI';
import { routeNames } from 'routes';

export const UnlockRoute: () => JSX.Element = () => {
  const { isLoggedIn } = useGetLoginInfo();

  const navigate = useNavigate();

  // React.useEffect(() => {
  //   if (isLoggedIn) {
  //     navigate(routeNames.xlvrg);
  //   }
  // }, [isLoggedIn]);

  return (
    <div className='home d-flex flex-fill align-items-center mb-5'>
      <div className='m-auto' data-testid='unlockPage'>
        <div
          className='card my-4 text-center'
          style={{
            background: 'none',
            border: 'none'
          }}
        >
          <div className='card-body py-4 px-2 px-sm-2 mx-lg-4'>
            <h4 className='mb-4 text-white'>Connect to MultiversX</h4>

            <ExtensionLoginButton
              callbackRoute={routeNames.xlvrg}
              loginButtonText={'Extension'}
            />
            <WebWalletLoginButton
              callbackRoute={routeNames.xlvrg}
              loginButtonText={'Web wallet'}
            />
            <LedgerLoginButton
              loginButtonText={'Ledger'}
              callbackRoute={routeNames.xlvrg}
              className={'test-class_name'}
            />
            <WalletConnectLoginButton
              callbackRoute={routeNames.xlvrg}
              loginButtonText={'xPortal'}
              isWalletConnectV2
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnlockRoute;
